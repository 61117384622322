exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-catering-tsx": () => import("./../../../src/templates/catering.tsx" /* webpackChunkName: "component---src-templates-catering-tsx" */),
  "component---src-templates-community-tsx": () => import("./../../../src/templates/community.tsx" /* webpackChunkName: "component---src-templates-community-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-donations-tsx": () => import("./../../../src/templates/donations.tsx" /* webpackChunkName: "component---src-templates-donations-tsx" */),
  "component---src-templates-faqs-tsx": () => import("./../../../src/templates/faqs.tsx" /* webpackChunkName: "component---src-templates-faqs-tsx" */),
  "component---src-templates-gift-cards-tsx": () => import("./../../../src/templates/gift-cards.tsx" /* webpackChunkName: "component---src-templates-gift-cards-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-locations-tsx": () => import("./../../../src/templates/locations.tsx" /* webpackChunkName: "component---src-templates-locations-tsx" */),
  "component---src-templates-menu-city-tsx": () => import("./../../../src/templates/menu-city.tsx" /* webpackChunkName: "component---src-templates-menu-city-tsx" */),
  "component---src-templates-menu-tsx": () => import("./../../../src/templates/menu.tsx" /* webpackChunkName: "component---src-templates-menu-tsx" */),
  "component---src-templates-rewards-tsx": () => import("./../../../src/templates/rewards.tsx" /* webpackChunkName: "component---src-templates-rewards-tsx" */),
  "component---src-templates-shop-merch-tsx": () => import("./../../../src/templates/shop-merch.tsx" /* webpackChunkName: "component---src-templates-shop-merch-tsx" */),
  "component---src-templates-third-party-delivery-tsx": () => import("./../../../src/templates/third-party-delivery.tsx" /* webpackChunkName: "component---src-templates-third-party-delivery-tsx" */)
}

