import { object, string } from 'yup';
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import ThirdPartyDeliveryForm from '../molecules/ThirdPartyDeliveryForm';
import startCase from 'lodash/startCase';
import axios from 'axios';

const validationSchema = object().shape({
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  email: string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  city: string().required('Please select the city'),
});

const ThirdPartyDeliveryFormSection = () => {
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);

  const {
    allWpCity: { nodes: cities },
  } = useStaticQuery(graphql`
    {
      allWpCity(sort: { fields: title }) {
        nodes {
          title
          city {
            cateringFormEmail
          }
        }
      }
    }
  `);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    city: cities[0].title,
  };

  const handleSubmit = async (values) => {
    setError(null);
    setSuccess(false);

    try {
      setSuccess(true);

      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: 'form_submission',
          formDetails: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            city: values.city,
          },
        });
      }
    } catch (e) {
      setError('Form submission has failed.');
    }
  };

  return (
    <section className="relative py-16 overflow-hidden bg-linen lg:py-24">
      <div className="mx-auto max-w-2xl px-5">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => <ThirdPartyDeliveryForm {...props} />}
        </Formik>
        <AnimatePresence>
          {error && (
            <motion.div
              className="text-red-accessibility pt-0.5 mt-6"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {success && (
            <motion.div
              key="success"
              className="flex items-center mt-6 space-x-6 text-2xl font-bold uppercase font-heading text-navy-blue"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div>
                Thank you for submitting the form. We will be in touch shortly.
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default ThirdPartyDeliveryFormSection;
