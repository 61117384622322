import Seo from '../components/base/Seo';
import React from 'react';
import Layout from '../components/base/Layout';
import { graphql } from 'gatsby';
import NewsletterSection from '../components/organisms/NewsletterSection';
import SimpleHeroSection from '../components/molecules/SimpleHeroSection';
import ThirdPartyDeliveryFormSection from '../components/organisms/ThirdPartyDeliveryFormSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const ThirdPartyDelivery = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo
          post={page}
          scripts={[
            `(function() {
            var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
            t.async = true;
            t.id    = 'cio-forms-handler';
            t.setAttribute('data-site-id', 'd54f253ef97acf6a574e');
            t.setAttribute('data-base-url', 'https://customerioforms.com');

            t.src = 'https://customerioforms.com/assets/forms.js';

            s.parentNode.insertBefore(t, s);
          })();`,
          ]}
        />

        <SimpleHeroSection>
          <div className="container text-center text-navy-blue">
            <div className="max-w-2xl mx-auto">
              <h1 className="mb-4 u-h2">YOU’RE ALMOST THERE!</h1>
              <div className="prose prose-md">
                Fill out the fields below to be automatically entered to win
                free coffee for 1 year + get $5 off your first delivery order
                directly from us!
              </div>
            </div>
          </div>

          <ThirdPartyDeliveryFormSection />
        </SimpleHeroSection>

        <div className="h-32" />

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query ThirdPartyDeliveryPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default ThirdPartyDelivery;
