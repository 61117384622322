import { Form, FormikProps } from 'formik';
import React from 'react';
import Button from '../atoms/Button';
import CityDropdownInput from '../atoms/CityDropdownInput';
import FormInput from '../atoms/FormInput';

const ThirdPartyDeliveryForm = (props: FormikProps<any>) => (
  <Form className="space-y-6">
    <div>
      <FormInput name="firstName" label="First name" />
    </div>
    <div>
      <FormInput name="lastName" label="Last name" />
    </div>
    <div>
      <FormInput
        id="emailAddress"
        name="email"
        type="email"
        label="Email address"
      />
    </div>
    <div>
      <CityDropdownInput name="city" label="City" placeholder="Please choose" />
    </div>
    <div className="pt-2">
      <Button type="submit" variant="default" disabled={props.isSubmitting}>
        {props.isSubmitting ? 'Submitting...' : 'Send'}
      </Button>
    </div>
  </Form>
);

export default ThirdPartyDeliveryForm;
